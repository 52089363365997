<template>
  <div class="container">
    <nav-box :title="title" :back="true"></nav-box>
    <div class="content flex-column">
      <span class="price-title">付款金额</span>
      <h2 class="price">￥{{ details.unPaidAmount }}</h2>
      <div class="old-price flex flex-sb">
        <div class="flex">
          <span>原价金额：</span>
          <p>￥{{ details.amount }}</p>
        </div>
        <div class="flex">
          <span>优惠金额：</span>
          <p>￥{{ details.discountAmount }}</p>
        </div>
      </div>
      <div class="line"></div>
      <span class="pay-title">
        请在下方出示“{{ handleType == 64 ? "支付宝" : "微信" }}”二维码
      </span>
      <div class="scan">
        <img src="@/assets/scan.png" alt="" />
        <div class="flex flex-sb">
          <span>{{ time }}s</span>
          <p @click="goBack">更换付款方式</p>
        </div>
      </div>
      <input type="text" ref="input" class="box" v-model="barcode" @keyup.enter="swipingCard" autofocus />
    </div>
    <div id="viewer"></div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
import axios from "axios";
export default {
  components: {
    navBox,
  },
  name: "scan",
  data() {
    return {
      title: "自助结账",
      payMethodId: "",
      handleType: null,
      barcode: "",
      details: {},
      time: 0,
      timer: null,
    };
  },
  mounted() {
    this.time = 15;
    setInterval(this.countDown, 1000);
    this.$refs.input.focus();
  },
  watch: {
    time: function (newVal, oldVal) {
      if (newVal == 0) {
        this.$router.go(-1);
      }
    },
    // barcode: {
    //   handler: function (val, oldVal) {
    //     if (this.timer) clearTimeout(this.timer);
    //     this.timer = setTimeout(() => {
    //       this.payOrder(val);
    //     }, 300);
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.handleType = this.$route.query.handleType;
    this.payMethodId = this.$route.query.payMethodId;
    this.details = JSON.parse(localStorage.getItem("details"));
  },
  methods: {
    swipingCard() {
      this.payOrder(this.barcode);
      this.barcode = "";
      this.$refs.input.focus();
    },
    goBack() {
      this.$router.go(-1);
    },
    countDown() {
      this.time--;
    },
    // 发起支付
    async payOrder(barcode) {
      let consuptionSubjectIds = localStorage.getItem("consuptionSubjectIds").split(",");
      let isRecycler = localStorage.getItem("isRecycler");
      let data = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        consumptionSubjectId:consuptionSubjectIds,
        payMethodId: this.payMethodId,
        amount: this.details.unPaidAmount,
        barcode: barcode,
      };
      // 支付
      this.$post(this.Api.pay, data).then((res) => {
        if (res.code == 1) {
          let fromData = {
            consumptionAreaId: localStorage.getItem("consumptionAreaId"),
            consuptionSubjectIds: consuptionSubjectIds,
            real: true,
            settlementType: 1,
            // orderId: res.data.payId,
          };
          // 结账
          this.$post(this.Api.transferOrder, fromData).then((res) => {
            if (res.code == 1) {
              this.PrintReport(res.data.id);
            }
          });
        }
      });
    },
    // 搜索报表
    dataSet(body, data) {
      var that = this;
      //创建报表对象
      var report = new window.Stimulsoft.Report.StiReport();
      // 加载文件
      report.load(body);
      //新建数据源
      var dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      //数据源填充数据
      dataSet.readJson(JSON.stringify(data));
      //给报表的数据源填充数据
      let storeName = localStorage.getItem("storeName");
      report.regData(dataSet.dataSetName, storeName, dataSet);
      // report.regBusinessObject("StoreName", "酒店名称");
      // 创建报表
      viewer.report = report;
      report.renderAsync(function () {
        report.print();
      });
    },
    PrintReport(orderId) {
      //获取打印报表
      let data = {
        type: "P01",
      };
      this.$fetch(this.Api.PrintReport + "/" + data.type)
        .then((res) => {
          let that = this;
          let requestUrl = res.data.dataInterfaceUrl;
          let body = res.data.body;

          let formData = {
            orderId: orderId,
            consumptionAreaId: localStorage.getItem('consumptionAreaId'),
          };
          axios
            .post(
              requestUrl,
              {
                ...formData,
              },
              {
                headers: {
                  access_token: localStorage.getItem("token"),
                  x_req_application_id: 1,
                  x_req_terminal: 1,
                  x_req_store_id: localStorage.getItem("storeId"),
                },
              }
            )
            .then(function (res) {
              that.dataSet(body, res.data.data);
              that.$router.push({
                path: "/result",
                query: {
                  payType: "payment",
                  result: true,
                },
              });
            })
            .catch(function (error) {
              console.log(error);
              that.$router.push({
                path: "/result",
                query: {
                  payType: "payment",
                  result: true,
                },
              });
            });
        })
        .catch(() => {
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#viewer {
  width: 0 !important;
  height: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  overflow: hidden;
}
.content {
  padding: 120px 197px 0 197px;
  align-items: center;
  justify-content: center;
  position: relative;
  .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 99;
    font-size: 1px;
    color: transparent;
  }
  .price-title {
    font-size: 40px;
    font-weight: 500;
    color: #606876;
    line-height: 58px;
    margin-bottom: 44px;
  }
  .price {
    font-size: 80px;
    font-weight: 500;
    color: #1a1e22;
    line-height: 98px;
    margin-bottom: 44px;
  }
  .old-price {
    width: 100%;
    margin-bottom: 70px;
    font-size: 26px;
    line-height: 38px;
    color: #b9bdc6;
    font-size: 500;
    margin-bottom: 68px;
    p {
      color: #1a1e22;
      text-decoration: line-through;
    }
  }
  .line {
    width: 100%;
    border: 3px dashed #b9bdc6;
    transform: rotate(180deg);
    margin-bottom: 140px;
  }
  .pay-title {
    font-size: 40px;
    line-height: 58px;
    font-weight: 500;
    color: #1a1e22;
    margin-bottom: 105px;
  }
  .payment {
    img {
      width: 270px;
      height: 270px;
      margin-right: 84px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .scan {
    img {
      width: 372px;
      height: 372px;
    }
    div {
      margin-top: 120px;
      font-size: 30px;
      line-height: 48px;
      font-weight: 500;
      color: #155bd4;
      position: relative;
      z-index: 999;
      p {
        text-decoration: underline;
      }
    }
  }
}
</style>